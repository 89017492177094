import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
    @Prop({ default: "600px", type: String })
    public maxWidth?: string

    @Prop({ default: false, type: Boolean })
    public state?: boolean;

    @Prop({ default: false, type: Boolean })
    public scrollable?: boolean;

    close(): void {
        this.$emit("close");
    }
}
