import { RequestFieldBadge } from "@/components";
import { DiffBadgeNature, RequestStatuses } from "@/enums";
import { IDiffBadge, IField } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";


@Component({
    name: "fieldDiffBadge",
    components: {
        RequestFieldBadge,
    },
})
export default class FieldDiffBadge extends Vue {
    @Prop({ default: null })
    public readonly field: IDiffBadge<IField>;

    @Prop({ default: RequestStatuses.Rejected })
    public readonly requestStatus: RequestStatuses;

    get badge(): unknown {
        if(this.field.nature === DiffBadgeNature.Added) {
            return {
                color: "green darken-3 font-weight-black",
                text: "+"
            };
        }
        else if(this.field.nature === DiffBadgeNature.Removed) {
            return {
                color: "red  darken-1 font-weight-black",
                text: "-"
            };
        }
        else{
            return null;
        }
    }

    get isNew(): boolean{
        return this.field.nature === DiffBadgeNature.New;
    }
}
