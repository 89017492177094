import Chart from "chart.js/auto";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ChartBar extends Vue{

    @Prop({ default: "chart-bar"}) readonly name: string
    @Prop({ default: {} }) readonly data: any
    @Prop({ default: false }) readonly stacked: boolean

    public chart: Chart;

    @Watch("data")
    public onDataSetChanged() : void {
        this.chart.data.labels = this.data.labels;
        this.chart.data.datasets = this.data.datasets;
        this.chart.update();
    }

    mounted(): void {
        const ctx = document.getElementById(this.name) as HTMLCanvasElement;
        const options = {
            responsive: true,
            scales: this.stacked ? {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: function(value, index, values) {
                            return Number.isInteger(value)? value : "";
                        }
                    }
                }
            } : {
                y: {
                    ticks: {
                        callback: function(value, index, values) {
                            return Number.isInteger(value)? value : "";
                        }
                    }
                }
            },
        };

        this.chart = new Chart(ctx, {
            type: "bar",
            data: this.data,
            options: options,
        });
    }
}
