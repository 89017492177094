import { IGroup, IDiffBadge } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import { DiffBadgeNature } from "@/enums";


@Component
export default class UserGroupDiffBadge extends Vue {
    @Prop({ default: null })
    public readonly scopeObject: IDiffBadge<IGroup>;

    get diffbadge(): unknown {
        if(this.scopeObject.nature == DiffBadgeNature.Added) {
            return {
                color: "green darken-3 font-weight-black",
                text: "+"
            };
        }
        else if(this.scopeObject.nature == DiffBadgeNature.Removed){
            return {
                color: "red darken-1 font-weight-black",
                text: "-"
            };
        }
        else {
            return null;
        }
    }

    get isNew(): boolean{
        return this.scopeObject.nature === DiffBadgeNature.New;
    }
}
