import { render, staticRenderFns } from "./ClientDatatable.vue?vue&type=template&id=617c8eb7&"
import script from "./ClientDatatable.ts?vue&type=script&lang=ts&"
export * from "./ClientDatatable.ts?vue&type=script&lang=ts&"
import style0 from "./ClientDatatable.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VCardTitle,VDataTable,VProgressCircular,VSwitch,VTextField})
