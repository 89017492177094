import { Vue, Component } from "vue-property-decorator";
import { Roles } from "@/enums";

@Component
export default class Sidebar extends Vue {
    get userName(): boolean {
        return this.$store.getters["account/userName"];
    }

    get userPhoto(): string {
        return this.$store.getters["account/userPhoto"];
    }

    get jobTitle(): string {
        return this.$store.getters["account/jobTitle"];
    }

    get isAdmin(): boolean {
        return this.$store.getters["account/user"].roles.includes(Roles.Administrator) ||
            this.$store.getters["account/user"].roles.includes(Roles.NewFieldsApprover) ||
            this.$store.getters["account/user"].roles.includes(Roles.GreenApprover) ||
            this.$store.getters["account/user"].roles.includes(Roles.OrangeApprover) ||
            this.$store.getters["account/user"].roles.includes(Roles.RedApprover) ||
            this.$store.getters["account/user"].roles.includes(Roles.AadAdmin);
    }

    get isAadAdmin(): boolean {
        return this.$store.getters["account/user"].roles.includes(Roles.Administrator) ||
            this.$store.getters["account/user"].roles.includes(Roles.AadAdmin);
    }

    get canManageFields(): boolean {
        return this.$store.getters["account/user"].roles.includes(Roles.Administrator) ||
            this.$store.getters["account/user"].roles.includes(Roles.NewFieldsApprover);
    }

    get sidebar(): boolean {
        return this.$store.getters["app/sidebar"];
    }

    set sidebar(state: boolean) {
        this.$store.dispatch("app/updateSidebar", state);
    }
}
