import { FieldClassifications, RequestStatuses, Roles } from "@/enums";
import { IField, IRequest, IRequestStep, IRequestApprovalForm } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RequestStepper extends Vue {
    @Prop({ default: null })
    public readonly request: IRequest;

    public comment = "";

    public aadClientId = "";

    public dialog = false;

    public isSubmitting = false;

    mounted(): void {
        this.aadClientId = (this.request.client == null) ? "" : this.request.client.aadClientId;
    }

    async approve(step: IRequestStep): Promise<void>{

        const nextStatus = this.steps.find((s) => s.index === step.index + 1 )?.type;

        if(!nextStatus && this.aadClientId === ""){
            this.$emit("error", "Aad Client ID & Name must be provided");
        }
        else {
            const requestApproval: IRequestApprovalForm = {
                requestId: this.request.id,
                approved: 1,
                status: step.type,
                nextStatus: nextStatus ? nextStatus : RequestStatuses.Closed,
                by: this.$store.getters["account/user"].email,
                comment: this.comment,
                aadClientId: nextStatus ? null : this.aadClientId,
            };
            this.isSubmitting = true;
            await this.submitApproval(requestApproval);
            this.isSubmitting = false;
        }
    }

    async reject(step: IRequestStep): Promise<void>{

        const requestApproval: IRequestApprovalForm = {
            requestId: this.request.id,
            approved: 0,
            status: step.type,
            nextStatus: RequestStatuses.Rejected,
            by: this.$store.getters["account/user"].email,
            comment: this.comment,
            aadClientId: null,
        };
        this.isSubmitting = true;
        await this.submitApproval(requestApproval);
        this.isSubmitting = false;
    }

    public isLastStep(step: IRequestStep): boolean{
        return step.type === RequestStatuses.AwaitingAadAdminsApproval;
    }

    public canApprove(step: IRequestStep): boolean {
        if(this.$store.getters["account/user"].roles.includes(Roles.Administrator)){
            return true;
        }
        else {
            switch(step.type){
                case RequestStatuses.AwaitingNewFieldsApproval:
                    return this.$store.getters["account/user"].roles.includes(Roles.NewFieldsApprover);
                case RequestStatuses.AwaitingGreenApproval:
                    return this.$store.getters["account/user"].roles.includes(Roles.GreenApprover);
                case RequestStatuses.AwaitingOrangeApproval:
                    return this.$store.getters["account/user"].roles.includes(Roles.OrangeApprover);
                case RequestStatuses.AwaitingRedApproval:
                    return this.$store.getters["account/user"].roles.includes(Roles.RedApprover);
                case RequestStatuses.AwaitingAadAdminsApproval:
                    return this.$store.getters["account/user"].roles.includes(Roles.AadAdmin);
                default:
                    return false;
            }
        }

    }

    private async submitApproval(requestApproval: IRequestApprovalForm){
        try{
            await this.$store.dispatch("app/approveRequest", requestApproval);
            this.$emit("success", "Request updated succesfully");
            this.comment = "";
        }
        catch(error: any) {
            if(error.response?.data.status === 403){
                this.$emit("error", "Unauthorized to process this action");
            }
            else {
                this.$emit("error", error.response?.data.title + " : " + error.response?.data.detail);
            }
        }
    }

    get requestGreenFields(): IField[] {
        if(this.request != null) {
            return this.request.fields.filter(f => f.classification === FieldClassifications.Green);
        } else {
            return null;
        }
    }

    get requestOrangeFields(): IField[] {
        if(this.request != null) {
            return this.request.fields.filter(f => f.classification === FieldClassifications.Orange);
        } else {
            return null;
        }
    }

    get requestRedFields(): IField[] {
        if(this.request != null) {
            return this.request.fields.filter(f => f.classification === FieldClassifications.Red);
        } else {
            return null;
        }
    }

    get requestNewFields(): IField[] {
        if(this.request != null) {
            return this.request.fields.filter(f => f.classification === FieldClassifications.NewField);
        } else {
            return null;
        }
    }

    get steps(): IRequestStep[] {
        const temp: IRequestStep[] = [];
        let index = 1;
        if(
            this.requestNewFields.length > 0 ||
            this.request.status === RequestStatuses.AwaitingNewFieldsApproval ||
            this.request.requestApprovals?.some(ra=> ra.approved === 1 && ra.status === RequestStatuses.AwaitingNewFieldsApproval)) {
            temp.push({
                title: "New fields approval",
                description: "Approval for new fields",
                index,
                color: "info",
                type: RequestStatuses.AwaitingNewFieldsApproval,
                fields: this.requestNewFields,
            });
            index++;
        }

        if(this.requestGreenFields.length > 0){
            temp.push({
                title: "Green approval",
                description: "Approval for green fields",
                index,
                color: "green",
                type: RequestStatuses.AwaitingGreenApproval,
                fields: this.requestGreenFields,
            });
            index++;
        }

        if(this.requestOrangeFields.length > 0){
            temp.push({
                title: "Orange approval",
                description: "Approval for orange fields",
                index,
                color: "orange",
                type: RequestStatuses.AwaitingOrangeApproval,
                fields: this.requestOrangeFields,
            });
            index++;
        }

        if(this.requestRedFields.length > 0){
            temp.push({
                title: "Red approval",
                description: "Approval for red fields",
                index,
                color: "red",
                type: RequestStatuses.AwaitingRedApproval,
                fields: this.requestRedFields,
            });
            index++;
        }

        temp.push({
            title: "App creation",
            description: "AAD App registration",
            index,
            color: "info",
            type: RequestStatuses.AwaitingAadAdminsApproval,
            fields: this.requestRedFields,
        });

        return temp;
    }

    get stepIndex(): number {
        const foundIndex = this.steps.find((s)=> s.type === this.request.status)?.index;
        return  foundIndex || 0;
    }
}
