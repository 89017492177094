import { Vue, Component, Model, Watch, Prop } from "vue-property-decorator";

import { snakeClient } from "@/services";
import { ISnakeApplication, ISnakeApplicationEntity } from "@/models";

@Component
export default class SnakePicker extends Vue {
    @Prop({ default: "Snake application", type: String })
    public readonly label?: string

    @Prop({ default: false, type: Boolean })
    public readonly closeOnClick?: boolean

    @Prop({ default: "snakeId", type: String })
    public readonly name?: string

    @Prop()
    public readonly errorMessages

    @Model("input")
    public selectedApplication?: string;

    public internalSelectedApplication: ISnakeApplication = null;

    public items: ISnakeApplication[] = [];

    public loading: boolean = false;

    public search: string = "";

    @Watch("search")
    public onSearchChanged(val: string): void {
        if (val && val.length >= 3) {
            this.querySelections(val.replace(/[^\x20-\xFF]/g, ""));
        }
    }

    @Watch("selectedApplication")
    public onSelectedApplicationChanged(val: ISnakeApplication): void {
        if(val != null){
            snakeClient.getApplicationById(this.selectedApplication).then(application => {
                this.items = [application];
                this.internalSelectedApplication = application;
            });
            this.internalSelectedApplication = val;
        } else {
            this.internalSelectedApplication = null;
        }
    }

    public querySelections(val: string): void {
        this.loading = true;
        snakeClient.getFilteredApplications(val).then(applications => {
            this.items = applications;
            this.loading = false;
        });
    }

    public onSelectApp(): void {
        this.$emit("input", this.internalSelectedApplication);
    }

    public getEntitiesHtml(entities: ISnakeApplicationEntity[]): string{
        return entities?.map(e => { return e.name }).join(', ');
    }

}
