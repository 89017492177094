var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.requests,"items-per-page":_vm.itemsPerPage,"sort-by":"requestedOn","sort-desc":true,"loading":_vm.loading,"search":_vm.search},on:{"click:row":_vm.handleClick,"update:items-per-page":_vm.handleItemsPerPage},scopedSlots:_vm._u([{key:"item.snakeAppLogo",fn:function(ref){
var item = ref.item;
return [(item.snakeAppIsPlatform)?_c('div',{staticClass:"table-platform"},[_vm._v("Platform")]):_vm._e(),_c('v-avatar',{staticClass:"ma-2"},[(_vm.loading)?_c('v-progress-circular',{staticClass:"ma-5",attrs:{"size":24,"width":2,"color":"purple","indeterminate":""}}):_c('img',{attrs:{"alt":"snakeBlue","src":item.snakeAppLogo ? item.snakeAppLogo : require('@/assets/images/snake-v2-blue.svg')}})],1)]}},{key:"item.snakeAppName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"app-name"},[_vm._v(_vm._s(item.snakeAppName))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(_vm._s(item.snakeAppId))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusButtonClass(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.statusButtonText(item.status))+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.description))])]}},{key:"item.requestedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.requestedOn))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }