import Chart from "chart.js/auto";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ChartPie extends Vue{

    @Prop({ default: "chart-pie"}) readonly name: string
    @Prop({ default: {} }) readonly data: any

    public chart : Chart;

    @Watch("data")
    public onDataSetChanged() : void {
        this.chart.data.labels = this.data.labels;
        this.chart.data.datasets = this.data.datasets;
        this.chart.update();
    }

    mounted(): void{
        const ctx = document.getElementById(this.name) as HTMLCanvasElement;
        this.chart = new Chart(ctx, {
            type: "pie",
            data: this.data,
        });
    }
}
