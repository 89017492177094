import { Vue, Component, Prop } from "vue-property-decorator";
import moment from "moment";
import { IClient, IClientDatatable } from "@/models";
import { RouteNames } from "@/router";
import { ClientStatuses } from "@/enums";

@Component
export default class ClientDatatable extends Vue {
    @Prop({ default: {} })
    public readonly headers: unknown;

    @Prop({ default: false })
    public readonly loading: boolean;

    @Prop({ default: [] })
    public readonly clients: IClient[];

    get itemsPerPage(): number{
        return this.$store.getters["app/itemsPerPage"];
    }

    public search = "";

    public handleClick(row: IClientDatatable): void {
        this.$router.push({ name: RouteNames.clientView, params: { id: row.id.toString() }});
    }

    public parseDate(date: string): string {
        return date == null ? "" : moment(date).format("LL");
    }

    public getItemDescription(item: IClient): string {
        return item.description;
    }

    public getItemRequestedOn(item: IClient): string {
        if(item.request)
            return this.parseDate(item.request.requestedOn);
        return "";
    }

    public getItemCreatedOn(item: IClient): string{
        if(item.createdOn)
            return this.parseDate(item.createdOn);
        return "";
    }

    public handleItemsPerPage(itemsPerPage: number): void{
        this.$store.dispatch("app/updateItemsPerPage", itemsPerPage);
    }

    public checkStatus(status: ClientStatuses): boolean{
        return status === ClientStatuses.Active;
    }

}
