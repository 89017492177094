import { Vue, Component } from "vue-property-decorator";

@Component
export default class Navbar extends Vue {

    get sidebar(): boolean {
        return this.$store.getters["app/sidebar"];
    }

    set sidebar(state: boolean) {
        this.$store.dispatch("app/updateSidebar", state);
    }

    get userName(): boolean {
        return this.$store.getters["account/userName"];
    }

    get userEmail(): boolean {
        return this.$store.getters["account/userEmail"];
    }

    get userPhoto(): string {
        return this.$store.getters["account/userPhoto"];
    }

    get jobTitle(): string {
        return this.$store.getters["account/jobTitle"];
    }

    public toggleDrawer(): void {
        this.sidebar = !this.sidebar;
    }

    public async logout(): Promise<void> {
        await this.$store.dispatch("account/logout");
    }
}
