import { FieldClassifications, RequestStatuses } from "@/enums";
import { IField } from "@/models";
import { Vue, Component, Prop } from "vue-property-decorator";


@Component
export default class FieldBadge extends Vue {
    @Prop({ default: null })
    public readonly field: IField;

    @Prop({ default: RequestStatuses.Rejected })
    public readonly requestStatus: RequestStatuses;

    get chipColor(): string {
        if([FieldClassifications.Green, FieldClassifications.Orange, FieldClassifications.Red].includes(this.field.classification)){
            return this.field.classification.toLowerCase();
        }
        else {
            return "info";
        }
    }

    get badge(): unknown {
        const index: number = Object.keys(RequestStatuses).indexOf(this.requestStatus);
        if(this.field.classification === FieldClassifications.NewField && index > 1 ||
            this.field.classification === FieldClassifications.Green && index > 2 ||
            this.field.classification === FieldClassifications.Orange && index > 3 ||
            this.field.classification === FieldClassifications.Red && index > 4) {
            return {
                color: "green",
                title: "Approved",
                icon: "mdi-check",
            };
        }
        else {
            return {
                color: "primary",
                title: "Pending...",
                icon: "mdi-timer-sand-full",
            };
        }

    }
}
