import { render, staticRenderFns } from "./RequestDiffUserGroupBadge.vue?vue&type=template&id=0a256606&"
import script from "./RequestDiffUserGroupBadge.ts?vue&type=script&lang=ts&"
export * from "./RequestDiffUserGroupBadge.ts?vue&type=script&lang=ts&"
import style0 from "./ScopeBadge.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VBadge,VChip})
