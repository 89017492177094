import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { IClient, IWorkDayPeople, IClientPreviewSearchFilter, IWorkDayPeopleField } from "@/models";
import { workDayPeopleRepository } from "@/repositories";

@Component
export default class WorkDayPeople extends Vue {

    @Prop({ default: null })
    public readonly client: IClient;

    public loading : boolean = false;

    public showSecret: boolean = false;

    public dialog: boolean = false;

    public clientSecret: string = null;

    public people: IWorkDayPeople[] = [];

    public oDataNextLink: string = null;
    
    public oDataCount: number = 0; 

    public search = "";

    public headers = [];

    get itemsPerPage(): number{
        return this.people.length;
    }

    public async loadPeople(): Promise<void>{
        this.people = [];
        this.loading = true;
        try {
            let filter: IClientPreviewSearchFilter = null;
            if(this.search !== ""){
                filter = {
                    text: this.search,
                    byEmail: true, 
                    byEmployeeId: true
                };
            }

            let apiHeaders = [];
            await workDayPeopleRepository.getAllPeople(this.client.aadClientId, this.clientSecret, filter).then(async (data) => {
                apiHeaders = data.headers;
                this.people = data.value;
                this.oDataNextLink = data.oDataNextLink;
                this.oDataCount = data.oDataCount;
            });
            
            this.loadHeaders(apiHeaders);
        }
        catch (error) {
            this.$emit("error", error);
        }
        
        this.loading = false;
    }

    /**
     * Load headers (filter) only for client's fields
     * @param people Headers comming from API
     */
    loadHeaders(apiHeaders: string[]){
        if(apiHeaders != null){
            this.headers.splice(0);
            for (let apiHeader of apiHeaders) {
                const clientFields = this.client.fields.filter(f => f.code.toLowerCase().includes(apiHeader.toLowerCase()));
                if(clientFields != null && clientFields.length > 0){
                    const colHeader = {
                        text: apiHeader, 
                        value: apiHeader, 
                        class: "nowrap", 
                        cellClass: "nowrap"
                    }
                    this.headers.push(colHeader);
                }
            }
        }
    }

    /**
     * Constructs the table line, putting each field in the right column
     * @param peopleTokens Tokens (field, value) for the current (line) people
     * @returns Right column line (completes with empty cells if needed)
     */
    getPeopleLine(peopleTokens: IWorkDayPeopleField[]): IWorkDayPeopleField[]{
        let tableTokens = [];
        for(let header of this.headers){
            const token = peopleTokens.filter(t => t.key == header.value);
            if(token == null || token.length == 0){
                const emptyToken = {
                    type: "string",
                    key: "",
                    value: ""
                }
                tableTokens.push(emptyToken);
            }
            else{
                tableTokens.push(token[0]);
            }
        }
        return tableTokens;
    }

    @Watch('search')
    onPropertyChanged() {
        this.loadPeople();        
    }

    public showSearch(): boolean{
        if(this.client?.fields != null)
        {
            const searchFields = this.client.fields.filter(f => f.code.includes("Email") || f.code.includes("EmployeeId"));
            return searchFields != null && searchFields.length > 0;
        }
        else return false;
    }

    public parseDate(date: string): string {
        return date == null ? "" : moment(date).format("LL");
    }

    public isDateField(type: string): boolean{
        return type == "date";
    }

    public parseBool(value: string): boolean{
        return value.toString().toLowerCase() == 'true';
    }

    public isBoolField(type: string): boolean{
        return type == "boolean";
    }

    public async handleNext(): Promise<void>{
        this.people = [];
        this.loading = true;
        try {
            await workDayPeopleRepository.getNextPeople(
                this.client.aadClientId, 
                this.clientSecret,
                this.oDataNextLink).then(async (data) => {
                    this.people = data.value;
                    this.oDataNextLink = data.oDataNextLink;
            });
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }
}
