import { FieldClassifications, RequestStatuses } from "@/enums";
import { IField, IRequest, IRequestApproval, IRequestTimelineItem } from "@/models";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class RequestStepper extends Vue {
    @Prop({ default: null })
    public readonly request: IRequest;

    get requestGreenFields(): IField[] {
        if(this.request != null) {
            return this.request.fields?.filter(f => f.classification === FieldClassifications.Green);
        } else {
            return null;
        }
    }

    get requestOrangeFields(): IField[] {
        if(this.request != null) {
            return this.request.fields?.filter(f => f.classification === FieldClassifications.Orange);
        } else {
            return null;
        }
    }

    get requestRedFields(): IField[] {
        if(this.request != null) {
            return this.request.fields?.filter(f => f.classification === FieldClassifications.Red);
        } else {
            return null;
        }
    }

    get requestNewFields(): IField[] {
        if(this.request != null) {
            return this.request.fields?.filter(f => f.classification === FieldClassifications.NewField);
        } else {
            return null;
        }
    }

    get steps(): IRequestTimelineItem[] {
        const temp: IRequestTimelineItem[] = [];
        let index = 1;

        temp.push({
            index: 1,
            chip: "Submitted",
            chipColor: "info",
            caption: "Submitted by: ",
            comment: "",
            by: this.request.requestedBy,
            color: "green",
            icon: "mdi-check",
            type: RequestStatuses.Rejected,
            on: this.parseDate(this.request.requestedOn),
        });

        this.request.requestApprovals?.forEach((approval)=>{
            index++;
            temp.push(this.getTimelineItem(index, approval));
        });

        if(this.request.status !== RequestStatuses.Rejected){
            let isNext = true;
            const indexOfCurrentStatus: number = Object.keys(RequestStatuses).indexOf(this.request.status);
            const indexOfClosed: number = Object.keys(RequestStatuses).indexOf(RequestStatuses.Closed);
            Object.keys(RequestStatuses).filter((status, i) => i >= indexOfCurrentStatus && i < indexOfClosed)?.forEach((status) => {
                if(this.showNextTimelineItem(RequestStatuses[status])){
                    index++;
                    temp.push(this.getNextTimelineItem(index, RequestStatuses[status], isNext));
                    isNext = false;
                }
            });
        }
        return temp;
    }

    public showNextTimelineItem(status: RequestStatuses): boolean {
        switch(status) {
            case RequestStatuses.AwaitingNewFieldsApproval:
                return this.requestNewFields != null && this.requestNewFields.length > 0;
            case RequestStatuses.AwaitingGreenApproval:
                return this.requestGreenFields != null && this.requestGreenFields.length > 0;
            case RequestStatuses.AwaitingOrangeApproval:
                return this.requestOrangeFields != null && this.requestOrangeFields.length > 0;
            case RequestStatuses.AwaitingRedApproval:
                return this.requestRedFields != null && this.requestRedFields.length > 0;
            default:
                return true;
        }
    }

    public getNextTimelineItem(index: number, status: RequestStatuses, isNext: boolean): IRequestTimelineItem {

        switch(status){
            case RequestStatuses.AwaitingNewFieldsApproval:
                return {
                    index,
                    chip: "New fields Approval",
                    chipColor: "info",
                    caption: isNext ? "Pending..." : "Awaiting...",
                    comment: "",
                    by: "",
                    color: "info",
                    icon: "",
                    type: RequestStatuses.AwaitingNewFieldsApproval,
                    on: "",
                };

            case RequestStatuses.AwaitingGreenApproval:
                return {
                    index,
                    chip: "Green Approval",
                    chipColor: "green",
                    caption: isNext ? "Pending..." : "Awaiting...",
                    comment: "",
                    by: "",
                    color: "info",
                    icon: "",
                    type: RequestStatuses.AwaitingGreenApproval,
                    on: "",
                };

            case RequestStatuses.AwaitingOrangeApproval:
                return {
                    index,
                    chip: "Orange Approval",
                    chipColor: "orange",
                    caption: isNext ? "Pending..." : "Awaiting...",
                    comment: "",
                    by: "",
                    color: "info",
                    icon: "",
                    type: RequestStatuses.AwaitingOrangeApproval,
                    on: "",
                };

            case RequestStatuses.AwaitingRedApproval:
                return {
                    index,
                    chip: "Red Approval",
                    chipColor: "red",
                    caption: isNext ? "Pending..." : "Awaiting...",
                    comment: "",
                    by: "",
                    color: "info",
                    icon: "",
                    type: RequestStatuses.AwaitingRedApproval,
                    on: "",
                };

            case RequestStatuses.AwaitingAadAdminsApproval:
                return {
                    index,
                    chip: "Aad App registration",
                    chipColor: "info",
                    caption: isNext ? "Pending..." : "Awaiting...",
                    comment: "",
                    by: "",
                    color: "info",
                    icon: "",
                    type: RequestStatuses.AwaitingAadAdminsApproval,
                    on: "",
                };

            default:
                return null;
        }
    }

    public getTimelineItem(index: number, approval: IRequestApproval): IRequestTimelineItem {
        switch(approval.status){
            case RequestStatuses.Rejected: {
                return {
                    index,
                    chip: "Rejected",
                    chipColor: "black",
                    caption: "",
                    by: "",
                    comment: "",
                    color: "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.Rejected,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.AwaitingNewFieldsApproval: {
                return {
                    index,
                    chip: "New fields Approval",
                    chipColor: "info",
                    caption: approval.approved ? "Approved by: ": "Rejected by: ",
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.AwaitingNewFieldsApproval,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.AwaitingGreenApproval: {
                return {
                    index,
                    chip: "Green Approval",
                    chipColor: "green",
                    caption: (approval.approved ? "Approved by: ": "Rejected by: "),
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.AwaitingGreenApproval,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.AwaitingOrangeApproval: {
                return {
                    index,
                    chip: "Orange Approval",
                    chipColor: "orange",
                    caption: approval.approved ? "Approved by: ": "Rejected by: ",
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.AwaitingOrangeApproval,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.AwaitingRedApproval: {
                return {
                    index,
                    chip: "Red Approval",
                    chipColor: "red",
                    caption: approval.approved ? "Approved by: ": "Rejected by: ",
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.AwaitingRedApproval,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.AwaitingAadAdminsApproval: {
                return {
                    index,
                    chip: "Aad App registration",
                    chipColor: "info",
                    caption: approval.approved ? "Approved by: ": "Rejected by: ",
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.AwaitingAadAdminsApproval,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.Enabled: {
                return {
                    index,
                    chip: "Enabled",
                    chipColor: "black",
                    caption: "Enabled by: ",
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.Enabled,
                    on: this.parseDate(approval.on),
                };
            }

            case RequestStatuses.Disabled: {
                return {
                    index,
                    chip: "Disabled",
                    chipColor: "black",
                    caption: "Disabled by: ",
                    by: approval.by,
                    comment: approval.comment,
                    color: approval.approved ? "green": "red",
                    icon: approval.approved ? "mdi-check" : "mdi-close",
                    type: RequestStatuses.Rejected,
                    on: this.parseDate(approval.on),
                };
            }
        }
    }

    public parseDate(date: string): string {
        return date == null ? "" : moment(date).format("LL");
    }
}
