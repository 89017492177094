import { Vue, Component, Prop } from "vue-property-decorator";
import { RequestStatuses } from "@/enums";
import moment from "moment";
import { IRequest, IRequestDatatable } from "@/models";
import { RouteNames } from "@/router";

@Component
export default class RequestDatatable extends Vue {
    @Prop({ default: {} })
    public readonly headers: unknown;

    @Prop({ default: false })
    public readonly loading: boolean;

    @Prop({ default: [] })
    public readonly requests: IRequest[];

    @Prop({ default: false })
    public readonly showSwitchButton: boolean;

    public showClosedRejected: boolean = false;

    get itemsPerPage(): number{
        return this.$store.getters["app/itemsPerPage"];
    }

    public search = "";

    public statusButtonClass(status: string): string {
        switch (status) {
            case RequestStatuses.AwaitingGreenApproval:
                return "success";
            case RequestStatuses.AwaitingOrangeApproval:
                return "warning";
            case RequestStatuses.AwaitingRedApproval:
                return "error";
            case RequestStatuses.Rejected:
                return "black";
            case RequestStatuses.Closed:
                return "purple";
            default:
                return "info";
        }
    }

    public statusButtonText(status: string): string {
        switch (status) {
            case RequestStatuses.AwaitingGreenApproval:
            case RequestStatuses.AwaitingOrangeApproval:
            case RequestStatuses.AwaitingRedApproval:
            case RequestStatuses.AwaitingNewFieldsApproval:
            case RequestStatuses.AwaitingAadAdminsApproval:
                return "Pending";
            case RequestStatuses.Rejected:
                return "Rejected";
            case RequestStatuses.Closed:
                return "Closed";
        }
    }

    public handleClick(row: IRequestDatatable): void {
        this.$router.push({ name: RouteNames.requestView, params: { id: row.id.toString() }});
    }

    public parseDate(date: string): string {
        return date == null ? "" : moment(date).format("LL");
    }

    public handleItemsPerPage(itemsPerPage: number): void{
        this.$store.dispatch("app/updateItemsPerPage", itemsPerPage);
    }

}
